import axios from "axios";
import {API_URL, base64ToBlob} from "../config";
import {
    GET_CALL_RESET_DICTIONARY,
    GET_CAMPAIGN_NAME_LIST, GET_FORCED_CLOSING_DICTIONARY,
    GET_MONITORING,
    GET_MONITORING_FOR_SIP,
    GET_MONITORING_PAGINATION,
    GET_MONITORINGS, GET_OWNER_TYPE_DICTIONARY,
    GET_STATUS_DICTIONARY,
    GET_USER_SORT_FIELDS,
    SHOW_DETAIL_LOADING,
    SHOW_SIP_DETAILS_LOADING
} from "../types/typesMonitoringList";
import {AUDIO_LOADING, GET_NOTIFICATION_ERROR, UPLOAD_AUDIO_LOADING} from "../types/types";
import {getAudioUrlForPlayer, hideError, hideLoader, showExportLoader, showLoader, updateSortFields} from "../actions/actionsMonitoringList";
import {showAccessDeniedNotifications, showNotFoundNotifications} from "../actions/actionsNotifications";
import {setUserError} from "../actions/actionsUsersList";
import {hideErrorMessage, showError} from "../actions/actions";
import {SET_CHECK_USER_FOR_BAN, SET_USER_STATUS_ERROR, SET_USER_STATUS_SUCCESS} from "../types/typesUsersList";

export function getMonitoringWithPaginationFilterAndSorting(data: any, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(showLoader())
        dispatch(hideError())
        dispatch({type: GET_NOTIFICATION_ERROR, payload: false});
        axios.post(`${API_URL}Monitoring/GetEventMonitorToUserGroup`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_MONITORINGS, payload: response.data.data.items})
                dispatch({type: GET_MONITORING_PAGINATION, payload: response.data.data})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch(hideLoader())
            })
    }
}

export function getUserSortFields(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        axios.get(`${API_URL}Monitoring/GetUserSortFields`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_USER_SORT_FIELDS, payload: response.data.data});
                localStorage.setItem('callcenter:user_fields', JSON.stringify(response.data.data?.fields));
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch({type: SET_USER_STATUS_ERROR, payload:  {message: 'Помилка завантаження даних'}})
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}

export function getEventMonitorToUserDetail(id: number, jwtToken: any, isSip?: boolean) {
    return (dispatch: (arg0: any) => void) => {
        if (isSip) {
            dispatch({type: SHOW_SIP_DETAILS_LOADING, payload: true})
        } else {
            dispatch({type: SHOW_DETAIL_LOADING, payload: true})
        }
        dispatch({type: GET_NOTIFICATION_ERROR, payload: false});
        dispatch(hideError());
        axios.get(`${API_URL}Monitoring/GetEventMonitorToUserDetail?callId=${id}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (isSip) {
                    dispatch({type: GET_MONITORING_FOR_SIP, payload: response.data.data});
                } else {
                    dispatch({type: GET_MONITORING, payload: response.data.data});
                }
            })
            .catch(() => {
                dispatch({type: SHOW_DETAIL_LOADING, payload: false})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: SHOW_DETAIL_LOADING, payload: false})
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    if (e.data) {
                        dispatch({type: SET_USER_STATUS_ERROR, payload:  {message: e.data.message}})
                    }
                    else {
                        dispatch({type: SET_USER_STATUS_ERROR, payload:  {message: 'Щось пішло не так.'}})
                    }
                }
            })
            .finally(() => {
                    dispatch(showAccessDeniedNotifications(false));
                    if (isSip) {
                        dispatch({type: SHOW_SIP_DETAILS_LOADING, payload: false});
                    } else {
                        dispatch({type: SHOW_DETAIL_LOADING, payload: false});
                    }
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: false});
                    dispatch({type: SET_USER_STATUS_ERROR, payload:  false})
                }
            )
    }
}

export function getTempUrl(jwtToken: any, sessionId: any, callDetailId: number) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError())
        dispatch({type: GET_NOTIFICATION_ERROR, payload: false});
        dispatch({type: AUDIO_LOADING, payload: true});
        axios.get(`${API_URL}Player/GetTempUrl?callDetailId=${callDetailId}&sessionId=${sessionId}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch(getAudioUrlForPlayer(response.data.data));
                } else {
                    dispatch(showNotFoundNotifications(true));
                    dispatch(getAudioUrlForPlayer(null));
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                }
                if (e?.response?.status === 404) {
                    dispatch(showNotFoundNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
                dispatch(getAudioUrlForPlayer(null));
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch(showNotFoundNotifications(false));
                dispatch(hideLoader());
                dispatch({type: AUDIO_LOADING, payload: false});
            })
    }
}

export function uploadTempUrl(jwtToken: any, sessionId: any, callDetailId: number) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError())
        dispatch({type: GET_NOTIFICATION_ERROR, payload: false});
        dispatch({type: UPLOAD_AUDIO_LOADING, payload: true});
        axios.get(`${API_URL}Player/GetTempUrl?callDetailId=${callDetailId}&sessionId=${sessionId}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then(async (response) => {
                if (response.data.statusCode === 200) {
                    dispatch(getAudioUrlForPlayer(response.data.data));
                    let audioUrl = response.data.data;
                    try {
                        const responseUrl = await axios.get(`${audioUrl}`, {
                            responseType: 'blob',
                            headers: {
                                cache: 'no-store',
                                'Cache-Control': 'no-cache'
                            }
                        });

                        const blob = new Blob([responseUrl.data], { type: responseUrl.headers['content-type'] });

                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');

                        link.href = url;
                        link.setAttribute('download', `${response.data.title}`);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        window.URL.revokeObjectURL(url);
                    } catch (error: any) {
                        if (error?.response?.status === 403) {
                            dispatch(showNotFoundNotifications(true));
                        }
                    }
                } else {
                    dispatch(showNotFoundNotifications(true));
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                }
                if (e?.response?.status === 404) {
                    dispatch(showNotFoundNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch(showNotFoundNotifications(false));
                dispatch(hideLoader());
                dispatch({type: UPLOAD_AUDIO_LOADING, payload: false});
            })
    }
}

export function getMonitoringExcel(dataSort: any, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError())
        dispatch(hideErrorMessage())
        dispatch(showExportLoader(true));
        axios.post(`${API_URL}Monitoring/GetMonitorExcelWithData`, dataSort,{
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            },
            responseType: 'json'
        })
            .then((response) => {
                const fileName = response.data.data.fileDownloadName;
                const fileContentsBase64 = response.data.data.fileContents;

                const blob = base64ToBlob(fileContentsBase64, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
                link.remove();
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showExportLoader(false));
                dispatch(showAccessDeniedNotifications(false));
                return dispatch(setUserError(false))
            })
    }
}

export function getCampaignFilterList(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        axios.get(`${API_URL}Monitoring/GetCampaignsAndQueues`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_CAMPAIGN_NAME_LIST, payload: response.data.data})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch({type: SET_USER_STATUS_ERROR, payload:  {message: 'Помилка завантаження даних'}})
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
            })
    }
}

export function updateUserFields(sortFields: any, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        axios.post(`${API_URL}Monitoring/UpdateUserSortFields`, sortFields, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch(updateSortFields(response.data.data?.fields));
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch({type: SET_USER_STATUS_ERROR, payload:  {message: 'Помилка оновлення Помилка оновлення стовпців'}})
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
            })
    }
}

export function checkCallBanUsers(userName: any, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(setUserError(false));
        axios.get(`${API_URL}CallBanUsersManager/CheckBanByNumber?number=${userName}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`,
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: SET_USER_STATUS_ERROR, payload:  {message: response.data.message + ` ${response.data?.data?.userName}`}})
                    dispatch({type: SET_CHECK_USER_FOR_BAN, payload: false});
                } else if (response.data.statusCode !== 500){
                    dispatch({type: SET_USER_STATUS_ERROR, payload:  {message: response.data.message}})
                    dispatch({type: SET_CHECK_USER_FOR_BAN, payload: false});
                } else {
                    dispatch({type: SET_CHECK_USER_FOR_BAN, payload: true});
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
                dispatch({type: SET_CHECK_USER_FOR_BAN, payload: false});
            })
    }
}

export function getStatusDirectoriesStatusType(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError());
        axios.get(`${API_URL}VoipDictionary/GetDictionary/true?parentId=100`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_STATUS_DICTIONARY, payload: response.data.data})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showError())
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}

export function getCallResetType(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError());
        axios.get(`${API_URL}VoipDictionary/GetDictionary/true?parentId=10`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_CALL_RESET_DICTIONARY, payload: response.data.data})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showError())
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}

export function getForcedClosingType(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError());
        axios.get(`${API_URL}VoipDictionary/GetDictionary/true?parentId=20`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_FORCED_CLOSING_DICTIONARY, payload: response.data.data})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showError())
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}

export function getOwnerTypeDictionary(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError());
        axios.get(`${API_URL}VoipDictionary/GetDictionary/true?parentId=30`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_OWNER_TYPE_DICTIONARY, payload: response.data.data})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showError())
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}
