import React, {createRef, forwardRef, useCallback, useEffect, useRef, useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {CSSTransition} from 'react-transition-group';
import {classNames} from 'primereact/utils';
import {Ripple} from 'primereact/ripple';
import {Badge} from 'primereact/badge';
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {setActiveClick} from "../../../redux/actions/actions";
import {DEV, menuConfig, STAGE} from "../../../redux/config";

const AppSubmenu = forwardRef((props: any, ref: any) => {
    const dispatch = useAppDispatch();
    const [activeIndex, setActiveIndex] = useState<any>(null);

    const onMenuItemClick = (event: any, item: any, index: any) => {
        if (item.disabled) {
            event.preventDefault();
            return;
        }

        //execute command
        if (item.command) {
            item.command({originalEvent: event, item: item});
            event.preventDefault();
        }

        if (item.items) {
            event.preventDefault();
        }

        if (props.root) {
            props.onRootMenuItemClick({
                originalEvent: event
            });
        }

        if (item.items) {
            setActiveIndex(index === activeIndex ? null : index);
        } else {
            if (props.menuMode !== 'sidebar') {
                const ink = getInk(event.currentTarget);
                if (ink) {
                    removeClass(ink, 'p-ink-active');
                }
            }
        }

        props.onMenuItemClick({
            originalEvent: event,
            item: item
        });
    };

    const onKeyDown = (event: any, item: any, index: any) => {
        if (event.key === 'Enter') {
            onMenuItemClick(event, item, index);
        }
    };

    const getInk = (el: any) => {
        for (let i = 0; i < el.children.length; i++) {
            if (typeof el.children[i].className === 'string' && el.children[i].className.indexOf('p-ink') !== -1) {
                return el.children[i];
            }
        }
        return null;
    };

    const removeClass = (element: any, className: string) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    };

    const onMenuItemMouseEnter = (index: any) => {
        if (props.root && props.menuActive && isHorizontalOrSlim() && !isMobile()) {
            setActiveIndex(index);
        }
    };

    const isMobile = () => {
        return window.innerWidth <= 991;
    };
    const isStatic = () => {
        return props.menuMode === 'static';
    };

    const isHorizontalOrSlim = useCallback(() => {
        return props.menuMode === 'horizontal' || props.menuMode === 'slim';
    }, [props.menuMode]);

    const visible = (item: any) => {
        return typeof item.visible === 'function' ? item.visible() : item.visible !== false;
    };

    const sortMenuItems = (items: any[]) => {
        const itemsWithSubmenu = items?.filter(item => item.items && item.items.length > 0);
        const itemsWithoutSubmenu = items?.filter(item => !item.items || item.items.length === 0);

        return [...itemsWithoutSubmenu, ...itemsWithSubmenu];
    };

    const getLink = (item: any, index: any) => {
        const menuitemIconClassName = classNames('layout-menuitem-icon', item.icon);
        const content = (
            <>
                <i className={menuitemIconClassName}></i>
                <span className="layout-menuitem-text">{item.label}</span>
                {item.items && <i className="pi pi-fw pi-chevron-down  layout-submenu-toggler"></i>}
                {item.badge && <Badge value={item.badge} severity="success"/>}

                <Ripple/>
            </>
        );
        const commonLinkProps = {
            style: item.style,
            className: classNames(item.className, 'p-ripple', {
                'p-disabled': item.disabled
            }),
            target: item.target,
            onClick: (e: any) => onMenuItemClick(e, item, index),
            onMouseEnter: () => onMenuItemMouseEnter(index),
            onKeyDown: (e: any) => onKeyDown(e, item, index)
        };

        const handleContextMenu = (event: { preventDefault: () => void; }) => {
            event.preventDefault();
        };

        if (item.to) {
            return (
                <NavLink onContextMenu={handleContextMenu} to={item.to} {...commonLinkProps} title={item.label}
                         className={({isActive}) => classNames(commonLinkProps.className, isActive ? 'active-route' : undefined)}
                         onClick={() => {
                             dispatch(setActiveClick({
                                 item: item.to,
                                 from: window.location.hash,
                                 click: true
                             }));
                         }}>
                    {content}
                </NavLink>
            );
        } else {
            return (
                <a href={item.url} onContextMenu={handleContextMenu} rel="noopener noreferrer"
                   tabIndex={item.url ? undefined : 0} {...commonLinkProps}>
                    {content}
                </a>
            );
        }
    };

    const getItems = () => {
        const sortedItems = sortMenuItems(props.items);

        return sortedItems.map((item, i) => {
            if (visible(item)) {
                const submenuRef = createRef<HTMLElement>();
                const active = activeIndex === i;
                const menuitemClassName = classNames({'layout-root-menuitem': props.root, 'active-menuitem': active && !item.disabled});
                const link = getLink(item, i);

                return (
                    <li key={item.label || i} className={menuitemClassName} role="menuitem">
                        {link}
                        {item.items && (
                            <>
                                {/* @ts-ignore: Ignoring TypeScript error for CSSTransition */}
                                <CSSTransition
                                    // @ts-ignore
                                    nodeRef={submenuRef}
                                    classNames="p-toggleable-content"
                                    timeout={{ enter: 1000, exit: 450 }}
                                    in={active}
                                    unmountOnExit
                                >
                                    <AppSubmenu
                                        ref={submenuRef}
                                        items={item.items}
                                        menuMode={props.menuMode}
                                        menuActive={props.menuActive}
                                        parentMenuItemActive={active}
                                        onMenuItemClick={props.onMenuItemClick}
                                    />
                                </CSSTransition>
                            </>
                        )}
                    </li>
                );
            }
            return null;
        });
    };

    useEffect(() => {
        if (props.resetActiveIndex && isHorizontalOrSlim()) {
            setActiveIndex(null);
        }
    }, [props.resetActiveIndex, isHorizontalOrSlim]);

    useEffect(() => {
        if (!props.menuActive && isHorizontalOrSlim() && !isMobile()) {
            setActiveIndex(null);
        }
    }, [props.menuActive, isHorizontalOrSlim]);

    if (!props.items) {
        return null;
    }

    const items = getItems();
    return (
        <ul ref={ref} className={props.className} role="menu">
            {items}
        </ul>
    );
});

const NavigationMenu = (props: any) => {
    const navigate = useNavigate();
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as any;

    const isOverlay = () => {
        return props.menuMode === 'overlay';
    };

    const isSidebar = () => {
        return props.menuMode === 'sidebar';
    };

    const clickMainPage = () => {
        const filteredMenuConfigWithRole = menuConfig.filter((item) =>
            rolesVision.hasOwnProperty(item.name)
        );
        if (filteredMenuConfigWithRole.length) {
            const order = rolesVision ? Object.keys(rolesVision) : [];

            filteredMenuConfigWithRole?.sort((a, b) => {
                const indexA = order?.indexOf(a.name);
                const indexB = order?.indexOf(b.name);
                return indexA - indexB;
            });

            if (filteredMenuConfigWithRole[0].name.toLowerCase() === 'dialer') {
                navigate(`/`);
            } else {
                navigate(`/${filteredMenuConfigWithRole[0].name.toLowerCase()}`);
            }
        }
    }

    const openMenu = () => {
        props.onSidebarMouseOver();
    }

    const handleDoubleClick = () => {
    };

    return (
        <div
            className={classNames(`layout-menu-wrapper ${DEV ? 'menu-wrapper-dev' : STAGE ? 'menu-wrapper-stage' : ''}`, {
                'layout-sidebar-active': props.sidebarActive
            })}
            onClick={props.onMenuClick}
            onMouseLeave={props.onSidebarMouseLeave}
        >
            <ul style={{
                ...(!props.pinActive
                    ? { margin: "-20px 0px 0px -67px"}
                    : { margin: "-20px 0 0 0"})
            }}  className={`lightrope lightrope-nav ${!props.animationEnabled ? "no-animation" : ""}`}>
                {Array.from({ length: 50 }).map((_, index) => (
                    <li key={index}></li>
                ))}
            </ul>
            <div className="menu-logo mt-2" style={props?.pinActive ? {} : {paddingLeft: '17px'}}>
                <button className="logo p-link relative" onClick={openMenu} onDoubleClick={handleDoubleClick}>
                    {/* @ts-ignore: Ignoring TypeScript error for CSSTransition */}
                    <CSSTransition
                        in={props.pinActive}
                        timeout={{enter: 500, exit: 0}}
                        classNames="slide"
                        unmountOnExit
                    >
                        <img
                            src={`/assets/layout/audio/NY2025/02.png`}
                            alt="logo"
                            className="logo logo-head slide"
                        />
                    </CSSTransition>
                    {/* @ts-ignore: Ignoring TypeScript error for CSSTransition */}
                    <CSSTransition
                        in={props.pinActive}
                        timeout={{enter: 500, exit: 0}}
                        classNames="slide"
                        unmountOnExit
                    >
                        <img
                            src={`/assets/layout/images/trr.svg`}
                            alt="logo"
                            className="logo"
                        />
                    </CSSTransition>
                    {/* @ts-ignore: Ignoring TypeScript error for CSSTransition */}
                    <CSSTransition
                        in={!props.pinActive}
                        timeout={{enter: 500, exit: 0}}
                        classNames="slide"
                        unmountOnExit
                    >
                        <img
                            src={`/assets/layout/images/snowman.png`}
                            alt="logo"
                            className="logo logo-snowman"
                        />
                    </CSSTransition>
                    {/* @ts-ignore: Ignoring TypeScript error for CSSTransition */}
                    <CSSTransition
                        in={!props.pinActive}
                        timeout={{ enter: 500, exit: 0 }}
                        classNames="slide"
                        unmountOnExit
                    >
                        <img
                            src={`/assets/layout/images/trr2.svg`}
                            alt="logo"
                            className="logo"
                        />
                    </CSSTransition>
                    {/*<p className="app-name text-logo text-phone">phone</p>*/}
                    {/* @ts-ignore: Ignoring TypeScript error for CSSTransition */}
                    <CSSTransition
                        in={props.pinActive}
                        timeout={{enter: 500, exit: 300}}
                        classNames="text-slide"
                        unmountOnExit
                    >
                        <p className="app-name text-logo text-phone">phone</p>
                    </CSSTransition>
                </button>
                <button className="app-name p-link"
                >
                    {/*<p className="app-name text-logo ml-2">phone</p>*/}
                </button>
                <button className="menu-pin p-link" onClick={props.onToggleMenu}>
                    {isOverlay() && <span className="pi pi-times"></span>}
                    {isSidebar() && !props.sidebarStatic && props.pinActive && <span className="pi pi-unlock"></span>}
                    {isSidebar() && props.sidebarStatic && props.pinActive && <span className="pi pi-lock"></span>}
                </button>
                <button onClick={() => props.close()} className="close-button"><span className="pi pi-times"></span>
                </button>
            </div>

            <div className="layout-menu-container">
                <AppSubmenu
                    items={props.model}
                    className="layout-menu"
                    menuMode={props.menuMode}
                    menuActive={props.menuActive}
                    root
                    openMenu={openMenu}
                    parentMenuItemActive
                    onMenuClick={props.onMenuClick}
                    onMenuItemClick={props.onMenuItemClick}
                    onRootMenuItemClick={props.onRootMenuItemClick}
                />
            </div>
        </div>
    );
};

export default NavigationMenu;
