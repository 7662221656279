import React, {useEffect, useRef, useState} from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {UserLoginData} from "../types/types";
import {accountWebLogin} from "../redux/api/api";
import {classNames} from "primereact/utils";
import {useNavigate} from "react-router-dom";
import {getRoleVision} from "../redux/api/apiRoles";
import {API_URL, menuConfig} from "../redux/config";
import {Toast} from "primereact/toast";
import {Divider} from "primereact/divider";
import {stopGlobalJsSIP} from "../components/JsSip/GlobalJsSIP";
import {CHANGE_CURRENT_USER_PASSWORD_SUCCESS, CHANGE_PASSWORD_SUCCESS} from "../redux/types/types";
import {googleAuthLoginError} from "../redux/actions/actions";
import {getUserSortFields} from "../redux/api/apiMonitoringList";
import {getStatisticsUserSortFields} from "../redux/api/apiStatisticsList";

export const Login = (props: any) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const toast = useRef<Toast>(null);
    let userReducer = useAppSelector(state => state.UsersReducer);
    // @ts-ignore
    let loading = useAppSelector(state => state.CompaniesListReducer.userLoading);
    const statusCode = JSON.parse(localStorage.getItem('callcenter:accountWebLogin') || '{}');
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as any;
    const rolesError = useAppSelector<any>(state => state.RolesReducer.error);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const [showPassword, setShowPassword] = useState(false);
    // @ts-ignore
    const googleAuthError = useAppSelector(state => state.CompaniesListReducer.googleAuthError) as any;
    // @ts-ignore
    const changePasswordSuccess = useAppSelector(state => state.UsersListReducer.changePasswordSuccess);
    const changeCurrentUserPasswordSuccess = useAppSelector(state => state.UsersReducer.changeCurrentUserPasswordSuccess);


    //NewYear2025

    const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
    const [audioBuffers, setAudioBuffers] = useState<Array<AudioBuffer | null>>([]);
    const audioFiles = Array.from({ length: 36 }, (_, i) =>
        `/assets/layout/audio/NY2025/sound${i + 1}.mp3`
    );

    useEffect(() => {
        const AudioContext = (window.AudioContext || (window as any).webkitAudioContext) as typeof window.AudioContext;
        const context = new AudioContext();
        setAudioContext(context);

        const loadAudioFiles = async () => {
            const buffers = await Promise.all(
                audioFiles.map(async (url) => {
                    const response = await fetch(url); // Локальные файлы из public
                    const arrayBuffer = await response.arrayBuffer();
                    return await context.decodeAudioData(arrayBuffer);
                })
            );
            setAudioBuffers(buffers);
        };

        loadAudioFiles();
    }, []);

    const playSound = (note: number) => {
        if (!audioContext || !audioBuffers[note]) return;

        const source = audioContext.createBufferSource();
        source.buffer = audioBuffers[note];
        source.connect(audioContext.destination);
        source.start(0);
    };

    const handleBallHover = (e: React.MouseEvent<HTMLDivElement>) => {
        const note = parseInt(e.currentTarget.dataset.note || '0');
        playSound(note);
        bounceAnimation(e.currentTarget);
    };

    const bounceAnimation = (element: Element) => {
        element.classList.add('bounce');
        setTimeout(() => element.classList.remove('bounce'), 300);
    };


    useEffect(() => {
        if (changePasswordSuccess) {
            let message = changePasswordSuccess?.message || 'Лист відновлення паролю відправлений';
            toast?.current?.show({severity: 'success', summary: message, detail: '', life: 3000});
            dispatch({type: CHANGE_PASSWORD_SUCCESS, payload: false});
        }
    }, [changePasswordSuccess]);

    useEffect(() => {
        if (!!changeCurrentUserPasswordSuccess) {
            // @ts-ignore
            let message = changeCurrentUserPasswordSuccess?.message || 'Лист відновлення паролю відправлений';
            toast?.current?.show({severity: 'success', summary: message, detail: '', life: 3000});
            setTimeout(() => {
                dispatch({type: CHANGE_CURRENT_USER_PASSWORD_SUCCESS, payload: false});
            }, 3000);        }
    }, []);

    useEffect(() => {
        stopGlobalJsSIP();
    }, []);

    useEffect(() => {
        if (!!googleAuthError) {
            let message = googleAuthError || 'Щось пішло не так.';
            toast?.current?.show({severity: 'error', summary: message, detail: '', life: 3000});
            setTimeout(() => {
                dispatch(googleAuthLoginError(false));
            }, 1000);
        }
    }, [googleAuthError]);

    useEffect(() => {
        if (rolesError) {
            toast?.current?.show({severity: 'error', summary: 'Щось пішло не так.', detail: '', life: 3000});
        }
    }, [rolesError]);

    useEffect(() => {
        if (!!googleAuthError) {
            let message = googleAuthError || 'Щось пішло не так.';
            toast?.current?.show({severity: 'error', summary: message, detail: '', life: 3000});
        }
    }, [googleAuthError]);

    const [userLogin, setUserLogin] = useState<UserLoginData>({
        userName: '',
        password: ''
    });

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        if (statusCode === 200) {
            if (!rolesVision && rolesError === false && jwtToken?.jwtToken) {
                dispatch(getRoleVision(jwtToken?.jwtToken));
            }

            dispatch(getUserSortFields(jwtToken?.jwtToken));
            dispatch(getStatisticsUserSortFields(jwtToken?.jwtToken));

            if (rolesVision) {
                const filteredMenuConfigWithRole = menuConfig.filter((item) =>
                    rolesVision.hasOwnProperty(item.name)
                );
                if (filteredMenuConfigWithRole.length) {
                    const order = rolesVision ? Object.keys(rolesVision) : [];

                    filteredMenuConfigWithRole?.sort((a, b) => {
                        const indexA = order?.indexOf(a.name);
                        const indexB = order?.indexOf(b.name);
                        return indexA - indexB;
                    });

                    if (filteredMenuConfigWithRole[0].name.toLowerCase() === 'dialer') {
                        navigate(`/`);
                        window.location.reload();
                    } else {
                        navigate(`/${filteredMenuConfigWithRole[0].name.toLowerCase()}`);
                        window.location.reload();
                    }
                }
            }

        }
    }, [rolesVision, statusCode, rolesError, jwtToken?.jwtToken]);

    const updateUserLoginData = (event: any) => {
        const value = event.target.value;
        const fieldName = event.target.name;
        let userLoginCopy = {...userLogin};
        // @ts-ignore
        userLoginCopy[fieldName] = value;
        setUserLogin(userLoginCopy);
    };

    const handleGoogleLogin = () => {
        const timestamp = new Date().getTime();
        window.location.href = `${API_URL}AccountWeb/google-login?timestamp=${timestamp}`;
    };

    const handleGoogleRegister = () => {
        const timestamp = new Date().getTime();
        window.location.href = `${API_URL}AccountWeb/google-register?timestamp=${timestamp}`;
    };

    return (
        <div className="login-body">
            <div className={classNames(`snowflakes`)} aria-hidden="true">
                <div className="snowflake">
                    ❅
                </div>
                <div className="snowflake">
                    ❅
                </div>
                <div className="snowflake">
                    ❆
                </div>
                <div className="snowflake">
                    ❄
                </div>
                <div className="snowflake">
                    ❅
                </div>
                <div className="snowflake">
                    ❆
                </div>
                <div className="snowflake">
                    ❄
                </div>
                <div className="snowflake">
                    ❅
                </div>
                <div className="snowflake">
                    ❆
                </div>
                <div className="snowflake">
                    ❄
                </div>
            </div>

            <Toast position="bottom-right" ref={toast}/>
            <div className="login-image">
                {/*<img src={`assets/layout/images/pages/login-${props.colorScheme === 'light' ? 'ondark' : 'onlight'}.png`} alt="atlantis"/>*/}
                <img src={`assets/layout/images/NYbg2.jpeg`} style={{maxWidth: '450px', objectFit: 'cover'}} alt="atlantis"/>
                {/*<img src={`assets/layout/images/NYbg2.jpeg`} style={{maxWidth: '450px', objectFit: 'cover'}} alt="atlantis"/>*/}
                {/*<img src={`assets/layout/images/NYbg2.jpeg`} style={{maxWidth: '450px', objectFit: 'cover'}} alt="atlantis"/>*/}
            </div>

            <div className="login-panel p-fluid">
                <div className="garland_image"></div>
                <div className="b-page_newyear">

                    <div className="b-page__content">

                        <i className="b-head-decor">

                            <i className="b-head-decor__inner b-head-decor__inner_n1">

                                <div className="b-ball b-ball_n1 b-ball_bounce" onMouseEnter={handleBallHover} data-note="0"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n2 b-ball_bounce" onMouseEnter={handleBallHover} data-note="1"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n3 b-ball_bounce" onMouseEnter={handleBallHover} data-note="2"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n4 b-ball_bounce" onMouseEnter={handleBallHover} data-note="3"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n5 b-ball_bounce" onMouseEnter={handleBallHover} data-note="4"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n6 b-ball_bounce" onMouseEnter={handleBallHover} data-note="5"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n7 b-ball_bounce" onMouseEnter={handleBallHover} data-note="6"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n8 b-ball_bounce" onMouseEnter={handleBallHover} data-note="7"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n9 b-ball_bounce" onMouseEnter={handleBallHover} data-note="8"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i1"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i2"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i3"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i4"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i5"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i6"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                            </i>

                            <i className="b-head-decor__inner b-head-decor__inner_n2">

                                <div className="b-ball b-ball_n1 b-ball_bounce" onMouseEnter={handleBallHover} data-note="9"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n2 b-ball_bounce" onMouseEnter={handleBallHover} data-note="10"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n3 b-ball_bounce" onMouseEnter={handleBallHover} data-note="11"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n4 b-ball_bounce" onMouseEnter={handleBallHover} data-note="12"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n5 b-ball_bounce" onMouseEnter={handleBallHover} data-note="13"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n6 b-ball_bounce" onMouseEnter={handleBallHover} data-note="14"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n7 b-ball_bounce" onMouseEnter={handleBallHover} data-note="15"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n8 b-ball_bounce" onMouseEnter={handleBallHover} data-note="16"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n9 b-ball_bounce" onMouseEnter={handleBallHover} data-note="17"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i1"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i2"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i3"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i4"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i5"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i6"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                            </i>

                            <i className="b-head-decor__inner b-head-decor__inner_n3">

                                <div className="b-ball b-ball_n1 b-ball_bounce" onMouseEnter={handleBallHover} data-note="18"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n2 b-ball_bounce" onMouseEnter={handleBallHover} data-note="19"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n3 b-ball_bounce" onMouseEnter={handleBallHover} data-note="20"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n4 b-ball_bounce" onMouseEnter={handleBallHover} data-note="21"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n5 b-ball_bounce" onMouseEnter={handleBallHover} data-note="22"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n6 b-ball_bounce" onMouseEnter={handleBallHover} data-note="23"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n7 b-ball_bounce" onMouseEnter={handleBallHover} data-note="24"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n8 b-ball_bounce" onMouseEnter={handleBallHover} data-note="25"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n9 b-ball_bounce" onMouseEnter={handleBallHover} data-note="26"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i1"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i2"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i3"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i4"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i5"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i6"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                            </i>

                            <i className="b-head-decor__inner b-head-decor__inner_n4">

                                <div className="b-ball b-ball_n1 b-ball_bounce" onMouseEnter={handleBallHover} data-note="27"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n2 b-ball_bounce" onMouseEnter={handleBallHover} data-note="28"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n3 b-ball_bounce" onMouseEnter={handleBallHover} data-note="29"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n4 b-ball_bounce" onMouseEnter={handleBallHover} data-note="30"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n5 b-ball_bounce" onMouseEnter={handleBallHover} data-note="31"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n6 b-ball_bounce" onMouseEnter={handleBallHover} data-note="32"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n7 b-ball_bounce" onMouseEnter={handleBallHover} data-note="33"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n8 b-ball_bounce" onMouseEnter={handleBallHover} data-note="34"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n9 b-ball_bounce" onMouseEnter={handleBallHover} data-note="35"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i1"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i2"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i3"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i4"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i5"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i6"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                            </i>

                            <i className="b-head-decor__inner b-head-decor__inner_n5">

                                <div className="b-ball b-ball_n1 b-ball_bounce" onMouseEnter={handleBallHover} data-note="0"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n2 b-ball_bounce" onMouseEnter={handleBallHover} data-note="1"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n3 b-ball_bounce" onMouseEnter={handleBallHover} data-note="2"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n4 b-ball_bounce" onMouseEnter={handleBallHover} data-note="3"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n5 b-ball_bounce" onMouseEnter={handleBallHover} data-note="4"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n6 b-ball_bounce" onMouseEnter={handleBallHover} data-note="5"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n7 b-ball_bounce" onMouseEnter={handleBallHover} data-note="6"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n8 b-ball_bounce" onMouseEnter={handleBallHover} data-note="7"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n9 b-ball_bounce" onMouseEnter={handleBallHover} data-note="8"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i1"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i2"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i3"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i4"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i5"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i6"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                            </i>

                            <i className="b-head-decor__inner b-head-decor__inner_n6">

                                <div className="b-ball b-ball_n1 b-ball_bounce" onMouseEnter={handleBallHover} data-note="9"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n2 b-ball_bounce" onMouseEnter={handleBallHover} data-note="10"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n3 b-ball_bounce" onMouseEnter={handleBallHover} data-note="11"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n4 b-ball_bounce" onMouseEnter={handleBallHover} data-note="12"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n5 b-ball_bounce" onMouseEnter={handleBallHover} data-note="13"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n6 b-ball_bounce" onMouseEnter={handleBallHover} data-note="14"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n7 b-ball_bounce" onMouseEnter={handleBallHover} data-note="15"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n8 b-ball_bounce" onMouseEnter={handleBallHover} data-note="16"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n9 b-ball_bounce" onMouseEnter={handleBallHover} data-note="17"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i1"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i2"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i3"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i4"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i5"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i6"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                            </i>

                            <i className="b-head-decor__inner b-head-decor__inner_n7">

                                <div className="b-ball b-ball_n1 b-ball_bounce" onMouseEnter={handleBallHover} data-note="18"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n2 b-ball_bounce" onMouseEnter={handleBallHover} data-note="19"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n3 b-ball_bounce" onMouseEnter={handleBallHover} data-note="20"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n4 b-ball_bounce" onMouseEnter={handleBallHover} data-note="21"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n5 b-ball_bounce" onMouseEnter={handleBallHover} data-note="22"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n6 b-ball_bounce" onMouseEnter={handleBallHover} data-note="23"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n7 b-ball_bounce" onMouseEnter={handleBallHover} data-note="24"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n8 b-ball_bounce" onMouseEnter={handleBallHover} data-note="25"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_n9 b-ball_bounce" onMouseEnter={handleBallHover} data-note="26"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i1"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i2"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i3"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i4"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i5"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                                <div className="b-ball b-ball_i6"><div className="b-ball__right"></div><div className="b-ball__i"></div></div>

                            </i>

                        </i>

                    </div>

                </div>
                <div className="flex flex-column">
                    <h3>Вхід</h3>
                    <div className="form-container">
                        <span className="p-input-icon-left">
                            <i className="pi pi-user"></i>
                            <InputText value={userLogin.userName} name="userName" onChange={(e) => updateUserLoginData(e)} type="text" placeholder="Логін" className={classNames('', {'p-invalid': userReducer.statusCode && userReducer.statusCode !== 200})}/>
                        </span>
                        <span className="p-input-icon-left">
                            <i className="pi pi-key"></i>
                            <InputText value={userLogin.password} name="password" onChange={(e) => updateUserLoginData(e)} type={showPassword ? 'text' : 'password'} placeholder="Пароль" className={classNames('pr-6', {'p-invalid': userReducer.statusCode && userReducer.statusCode !== 200})}/>
                            <i className={showPassword ? 'pi pi-eye-slash cursor-pointer text-black show-pass text-primary' : 'pi pi-eye cursor-pointer show-pass text-primary'} onClick={togglePasswordVisibility}></i>
                        </span>
                        {userReducer.message && <small id="username-help" className="p-error">{userReducer.message}</small>}
                    </div>
                    <div className="button-container">
                        <Button onClick={() => dispatch(accountWebLogin(userLogin))} loading={loading} className="mt-3 mb-3" type="button" label="Увійти"></Button>
                    </div>
                    <div className="button-container">
                        <a target="_blank" onClick={() => navigate('/auth/forgotpassword')} className="flex mb-2 text-sm text-primary cursor-pointer">Забули свій пароль ?</a>
                    </div>
                    <div className="flex align-items-center">
                        <Divider align="left"></Divider>
                        <p className="m-0 mx-2">або</p>
                        <Divider align="left"></Divider>
                    </div>
                    <div className="button-container flex justify-content-between">
                        <a className="w-full border-round-lg mr-2 mt-3 p-2 google-button flex align-items-center justify-content-center" onClick={handleGoogleLogin}><svg style={{maxWidth: '15px', minWidth: '15px'}} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlnsXlink="http://www.w3.org/1999/xlink" className="block mr-2">
                            <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                            <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                            <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                            <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                            <path fill="none" d="M0 0h48v48H0z"></path>
                        </svg><label className="cursor-pointer">Увійти</label></a>

                        <a className="border-round-lg mt-3 p-2 google-button flex align-items-center justify-content-center" onClick={handleGoogleRegister}><svg style={{maxWidth: '15px', minWidth: '15px'}} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlnsXlink="http://www.w3.org/1999/xlink" className="block mr-2">
                            <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                            <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                            <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                            <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                            <path fill="none" d="M0 0h48v48H0z"></path>
                        </svg><label className="cursor-pointer">Зареєструватися</label></a>
                    </div>
                </div>

                <div className="login-footer flex align-items-center">
                    <span>Copyright {new Date().getFullYear()}</span>
                </div>
            </div>
        </div>
    );
};
