import React, {useRef} from 'react';
import {classNames} from 'primereact/utils';
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {initialAccountData, setChangedCompaniesData, setCompanyStateSave, setDialogVisible, setErrorFields} from "../../../redux/actions/actions";
import {setRoleDialogVisible, setRolesVisible} from "../../../redux/actions/actionsRoles";
import {Badge} from "primereact/badge";
import {Dropdown} from "primereact/dropdown";
import {updateUserStatus} from "../../../redux/api/apiUsersList";
import SIPCallComponent from "../../JsSip";
import {ERROR_ACCOUNT_WEB_LOGIN} from "../../../redux/types/types";
import {setStatusDialogVisible} from "../../../redux/actions/actionsStatuses";
import {setUserDialogUnzipping, setUsersDialogVisible} from "../../../redux/actions/actionsUsersList";
import {stopGlobalJsSIP} from "../../JsSip/GlobalJsSIP";
import {Button} from "primereact/button";
import {setReconnectSession} from "../../../redux/actions/actionsWebRTC";
import InternetStatus from "../InternetStatus";
const ProfileMenu = (props: any) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const user = useAppSelector(state => state.UsersReducer.userData);
    // @ts-ignore
    const userStatus = useAppSelector(state => state.UsersListReducer.userStatus);
    const activeSession = useAppSelector(state => state.WebRTCReducer.activeSession);
    const statuses = useAppSelector(state => {
        // @ts-ignore
        const currentStatuses = state.UsersListReducer.statuses;
        const additionalStatus = {
            id: 1,
            externalName: "LOGOUT",
            statusName: "Logout",
            displayOrder: 1,
            statusColor: "rgb(177, 177, 177)",
            statusImagePath: null,
            description: ""
        };
        if (currentStatuses?.length) {
            return [...currentStatuses, additionalStatus];
        } else {
            return [additionalStatus];
        }
    });
    const dropdownRef = useRef<any>(null);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const handleStatusChange = (event: any) => {
        let data = {
            userId: user?.userId,
            statusesId: event.value
        }
        if (event.value !== 1) {
            dispatch(updateUserStatus(data, jwtToken?.jwtToken, null, true));
        } else {
            dispatch(updateUserStatus(data, jwtToken?.jwtToken, null, true));
            navigate('/login');
            stopGlobalJsSIP();
            localStorage.clear();
            localStorage.removeItem('persist:callcenter:user');
            dispatch(initialAccountData());
            dispatch(setRolesVisible(null));

            dispatch(setDialogVisible(false));
            dispatch(setStatusDialogVisible(false));
            dispatch(setUsersDialogVisible(false));
            dispatch(setUserDialogUnzipping(false));
            dispatch(setCompanyStateSave(false));
            dispatch(setChangedCompaniesData(false));
            dispatch(setErrorFields(null));
            dispatch(setRoleDialogVisible(false));

            dispatch({type: ERROR_ACCOUNT_WEB_LOGIN, payload: {"statusCode": null, "title": null, "message": null, "data": null}})
        }
    };

    const statusClass = classNames({
        'p-badge status-unsuccessful': userStatus === 'Unsuccess',
        'p-badge status-success': userStatus
    });

    const getStatusNameById = (id: number) => {
        const selectedStatus = statuses.find((status: { id: number; }) => status.id === id);
        const replaceStatusName = selectedStatus?.statusName?.replace(/Copy.*/, 'Copy...');
        return replaceStatusName ? replaceStatusName : '';
    }

    const filteredStatusList = statuses?.filter((status: { isAutoStatus: boolean; }) => !status.isAutoStatus);

    function customItemTemplate(option: any) {
        return (<>
                {option?.id !== 1 ?<div className="custom-dropdown-option">
                        {option.statusName}
                    </div> :
                    <ul className="list-none p-0"><li className="layout-inline-menu-action-item">
                        <button className="p-link text-white">
                            <i className="pi pi-fw pi-sign-out mr-2"></i>
                            <span>{option.statusName}</span>
                        </button>
                    </li></ul>}
            </>
        );
    }

    const handleBadgeClick = () => {
        if (dropdownRef.current) {
            dropdownRef.current.show();
        }
    };

    const clickActive = () => {
        if (!activeSession) {
            dispatch(setReconnectSession(true));
        }
    };

    return (
        <div className={classNames('layout-rightmenu ', {'layout-rightmenu-active': props.profileMenuActive})} onClick={props.onProfileMenuClick} style={{zIndex: '1103'}}>
            <ul style={{margin: '-51px 0px 0px -27px'}} className={`lightrope lightrope-nav ${!props.animationEnabled ? "no-animation" : ""}`}>
                {Array.from({ length: 50 }).map((_, index) => (
                    <li key={index}></li>
                ))}
            </ul>
            <button onClick={() => props.profileMenuActiveChange(false)} className="layout-rightmenu-close p-link" style={{marginTop: '5px'}}>
                <i className="pi pi-times"></i>
            </button>
            <div className="user-detail-wrapper pb-0 mb-4 pt-0">
                <div className="user-detail-content mb-0">
                    <InternetStatus/>
                    {activeSession ?
                        <Button icon="pi pi-phone"  className="text-xl phone-icon outline-none shadow-none border-none cursor-pointer p-button-success text-white mr-2 border-circle ml-2" style={{backgroundColor: 'rgb(76, 175, 80)', width: '40px', height: '40px', fontSize: '40px'}}></Button>
                        : <Button icon="pi pi-phone"  className="text-xl phone-icon outline-none shadow-none cursor-pointer p-button-success text-white mx-2 border-circle relative overflow-visible flex justify-content-center ml-2" style={{backgroundColor: '#b3bac5', width: '40px', height: '40px', fontSize: '40px', border: '1px solid red'}} onClick={clickActive}><i className="offline-icon-phone pi pi-times"></i>
                        </Button>}
                    {/*<button className=" outline-none shadow-none border-none border-circle cursor-pointer flex align-items-center relative p-0 mr-2 overflow-hidden " style={{backgroundColor: '#b3bac5', width: '40px', height: '40px', fontSize: '40px', objectFit: 'cover'}} ><img style={{width: '40px', height: '40px'}} src={"assets/layout/images/default.svg"} alt="avatar"/></button>*/}
                    <div className="user-detail-content-right">
                        {/*{user ? <span className="user-name">ekaterina.gorlova.PreColl</span> : <span className="user-name">Unknown</span>}*/}
                        {userStatus && userStatus?.statusColor && <div className="flex p-0" style={{minWidth: '160px'}}>
                            <Badge onClick={handleBadgeClick} value={getStatusNameById(userStatus?.statusesId)} className={`${statusClass} text-white customer-badge px-2 text-xs p-0 cursor-pointer`} style={{borderRadius: '6px 0 0 6px', backgroundColor: `${userStatus?.statusColor}`}}/>
                            <div className="status-selector">
                                <Dropdown
                                    ref={dropdownRef}
                                    value={userStatus?.statusesId}
                                    options={filteredStatusList}
                                    onChange={handleStatusChange}
                                    filter
                                    height={300}
                                    showClear
                                    panelClassName="statusNamePanel statusName"
                                    optionLabel="statusName"
                                    optionValue="id"
                                    style={{borderRadius: '0 6px 6px 0', borderColor: userStatus?.statusColor}}
                                    className={classNames(`statusName`, {'p-inputtext-filled h-full shadow-none': userStatus})}
                                    // disabled={!hasUsersManagerUpdatePermission}
                                    itemTemplate={customItemTemplate}
                                />
                            </div>
                        </div>}
                    </div>
                </div>
                {/*{user ? <span className="user-name pb-2" style={{wordBreak: "break-all"}}>{user?.userName}*/}
                {/*    </span> : <span className="user-name">Unknown</span>}*/}

                <div className="layout-inline-menu m-0 mt-2">
                    <ul className="layout-inline-menu-action-panel m-0">
                        <li className="layout-inline-menu-action-item m-0">
                            <button className="p-link">
                                <i className="pi pi-user pi-fw"></i>
                                <span className="text-xl" style={{wordBreak: "break-all"}}>{user?.userName || 'Unknown'}</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <SIPCallComponent statusHubConnectionState={props.statusHubConnectionState} profileMenuPinChange={props.onRightMenuPinChange} profileMenuActiveChange={props.profileMenuActiveChange}/>
        </div>
    );
};

export default ProfileMenu;
