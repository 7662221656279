import {FilterMatchMode, FilterOperator} from "primereact/api";
import {AUDIO_LOADING, UPLOAD_AUDIO_LOADING} from "../types/types";
import {
    CLEAR_STATISTICS_FILTERS,
    GET_STATISTIC,
    GET_STATISTICS,
    GET_STATISTICS_PAGINATION,
    HIDE_LOADER,
    INITIAL_STATISTICS_DATA,
    SELECTED_STATISTICS_ID,
    SET_CURRENT_STATISTICS_PAGE,
    SET_CURRENT_STATISTICS_SIZE, SET_STATISTICS_FILTER, SET_STATISTICS_LOADING,
    SHOW_DETAIL_LOADING, SHOW_EXPORT_STATISTICS_LOADING,
    STATISTICS_TABLE_TOGGLE
} from "../types/typesStatisticsList";
import {GET_AUDIO_URL} from "../types/typesMonitoringList";


const initialState = {
    statisticsTableToggle: 'table1',
    selectedStatisticId: null,
    clearFilters: {
        nameFrom: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        nameTo: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        ownerUserName: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        ownerType: {value: null, matchMode: FilterMatchMode.IN},
        fullName: {value: null, matchMode: FilterMatchMode.IN},
        campaignFullName: {value: null, matchMode: FilterMatchMode.IN},
        queueFullName: {value: null, matchMode: FilterMatchMode.IN},
        status: {value: null, matchMode: FilterMatchMode.IN},
        callResetName: {value: null, matchMode: FilterMatchMode.IN},
        amdHangup: {value: null, matchMode: FilterMatchMode.IN},
        callType: {value: null, matchMode: FilterMatchMode.IN},
        direction: {value: null, matchMode: FilterMatchMode.IN},
        campaignId: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        start: {value: null, matchMode: FilterMatchMode.IN},
        fullCallDuration: {value: null, matchMode: FilterMatchMode.IN},
        duration: {value: null, matchMode: FilterMatchMode.IN},
        reactionClient: {value: null, matchMode: FilterMatchMode.IN},
        reactionOperator: {value: null, matchMode: FilterMatchMode.IN},
        end: {value: null, matchMode: FilterMatchMode.IN},
    },
    statisticsList: [],
    campaignListName: [],
    checkUserForBan: false,
    pagination: {
        hasNext: 0,
        pageNumber: 0,
    },
    paginationPage: 1,
    paginationSize: 10,
    statisticData: {
        duration: null,
        phoneNo: null,
        contactName: null,
        description: null,
        url: null,
        end: null,
        id: null,
        campaignId: null,
        campaignName: null,
        nameFrom: null,
        nameTo: null,
        status: null,
        typeChannel: null,
        start: null,
        direction: null,
        isDeclined: false,
        username: null,
        hasDetails: false,
        sessionId: null,
        amdHangup: null,
        callReset: null
    },
    audioUrl: null,
    audioUrlLoading: false,
    audioUrlUploadLoading: false,
    loading: false,
    exportLoading: false,
    detailsLoading: false,
    sipDetailsLoading: false,
    error: false,
    errorUpdate: false,
    errorMessage: null,
    errorField: null,
    errorFields: null,
    pageChanged: false,
    statusDictionary: null,
    callResetDictionary: null,
    forcedClosingDictionary: null,
    statisticFilterData: null
}

export const StatisticsListReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case STATISTICS_TABLE_TOGGLE:
            return {...state, statisticsTableToggle: action.payload}
        case SELECTED_STATISTICS_ID:
            return {...state, selectedStatisticId: action.payload}
        case CLEAR_STATISTICS_FILTERS:
            return {
                ...state, clearFilters: {
                    nameFrom: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    ownerUserName: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    ownerType: {value: null, matchMode: FilterMatchMode.IN},                    nameTo: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    fullName: {value: null, matchMode: FilterMatchMode.IN},
                    campaignFullName: {value: null, matchMode: FilterMatchMode.IN},
                    queueFullName: {value: null, matchMode: FilterMatchMode.IN},
                    status: {value: null, matchMode: FilterMatchMode.IN},
                    callResetName: {value: null, matchMode: FilterMatchMode.IN},
                    amdHangup: {value: null, matchMode: FilterMatchMode.IN},
                    callType: {value: null, matchMode: FilterMatchMode.IN},
                    direction: {value: null, matchMode: FilterMatchMode.IN},
                    campaignId: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    start: {value: null, matchMode: FilterMatchMode.IN},
                    fullCallDuration: {value: null, matchMode: FilterMatchMode.IN},
                    duration: {value: null, matchMode: FilterMatchMode.IN},
                    reactionClient: {value: null, matchMode: FilterMatchMode.IN},
                    reactionOperator: {value: null, matchMode: FilterMatchMode.IN},
                    end: {value: null, matchMode: FilterMatchMode.IN}
                }
            }
        case GET_STATISTICS:
            return {...state, statisticsList: action.payload}
        case GET_STATISTICS_PAGINATION:
            return {...state, pagination: {hasNext: action.payload.hasNext, pageNumber: action.payload.pageNumber}}
        case SET_CURRENT_STATISTICS_PAGE:
            return {...state, paginationPage: action.payload}
        case SET_CURRENT_STATISTICS_SIZE:
            return {...state, paginationSize: action.payload}
        case GET_STATISTIC:
            return {...state, statisticData: action.payload}
        case SET_STATISTICS_LOADING:
            return {...state, loading: action.payload}
        case HIDE_LOADER:
            return {...state, loading: false}
        case INITIAL_STATISTICS_DATA:
            return {...state, statisticData: initialState.statisticData}
        case SHOW_DETAIL_LOADING:
            return {...state, detailsLoading: action.payload}
        case SHOW_EXPORT_STATISTICS_LOADING:
            return {...state, exportLoading: action.payload}
        case GET_AUDIO_URL:
            return {...state, audioUrl: action.payload}
        case AUDIO_LOADING:
            return {...state, audioUrlLoading: action.payload}
        case UPLOAD_AUDIO_LOADING:
            return {...state, audioUrlUploadLoading: action.payload}
        case SET_STATISTICS_FILTER:
            return {...state, statisticFilterData: action.payload}
        default:
            return state
    }
}
