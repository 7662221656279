import {PROD} from "../config";

interface CampaignFormErrors {
    name?: object;
    callCycles?: any;
    trankId?: boolean,
    startTime?: boolean,
    startDate?: boolean,
    endTime?: boolean,
    endDate?: boolean,
    campaignUsers?: object;
    branchId?: boolean;
}

interface QueueFormErrors {
    name?: object;
    queueTranks?: object;
    scheduler?: object;
    queueUsers?: object;
    mohAudioId?: object;
    mohAudioTime?: object;
    schedulerAudioTime?: object;
    mwtAudioTime?: object;
    mwtNoAgentAudioTime?: object;
    schedulerStrategyAction?: object;
    mwtStrategyAction?: object;
    mwtNoAgentStrategyAction?: object;
    mwt?: object;
    mwtNoAgent?: object;
    schedulerStrategy?: object;
    mwtStrategy?: object;
    mwtNoAgentStrategy?: object;
    branchId?: boolean;
}


interface BranchDataFormErrors {
    ownerName?: object;
    name?: object;
    parentId?: object
}

interface RoleDataFormErrors {
    name?: object;
    description?: object;
    permissionId?: object
    branchId?: boolean;
}

interface StatusDataFormErrors {
    name?: object;
    description?: object;
    timeStatus?: object;
    branchId?: boolean;
}

interface AudioDataFormErrors {
    audioName?: object;
    description?: object;
    timeStatus?: object;
    branchId?: boolean;
    audioFileName?: object;
}

interface QualificationGroupDataFormErrors {
    name?: object;
    users?: object;
    branchId?: boolean;
}

interface UserFormErrors {
    userName?: any;
    roles?: any;
    password?: any,
    recruitedDate?: boolean,
    birthDate?: boolean,
    branchId?: boolean,
    phoneNo?: boolean,
    languageId?: boolean,
    email?: any,
    countryId?: boolean,
    utc?: boolean,
    firstName?: boolean,
    lastName?: boolean,
    userTrunks?: object,
    mobilePhone?: any
}

interface CallCycleErrors {
    countCalls?: string | boolean;
    timeBetweenCalls?: string | boolean;
}

interface ChangePasswordErrors {
    oldPassword?: string | boolean | any;
    newPassword?: string | boolean | any;
    confirmationPassword?: string | boolean | any;
}

interface TrunkFormErrors {
    name?: object;
    phones?: object;
    listOutboundPhones?:object;
    listInboundPhones?: object;
}

interface recoveryEmailErrors {
    name?: object;
}

export function validateQualificationGroup(skillGroup: any): QualificationGroupDataFormErrors {
    const errors: QualificationGroupDataFormErrors = {};

    if (!skillGroup?.name) {
        errors.name = {name: 'Назва групи не може бути порожньою*'};
    }

    if (!skillGroup.skillGroupBranches?.length) {
        errors.branchId = true;
    }

    // if (!skillGroup?.users?.length) {
    //     errors.users = {users: 'Відсутні оператори*'};
    // }

    return errors;
}

export function validateUserForm(userForm: any): UserFormErrors | null {
    const errors: UserFormErrors = {};

    if (!userForm.userName) {
        errors.userName = true;
    } else {
        const regex = /^[A-Za-z\d._!*]*[A-Za-z]+[\d._!*]*$/;

        if (!regex.test(userForm.userName)) {
            errors.userName = {name: `Недопустимі символи! Логін має містити хоча б одну літеру. Використовуйте символи A-Z a-z 0-9 . _ ! * .`};
        }
    }

    if (!userForm?.mobilePhone && userForm?.callTypeReceive === 403) {
        errors.mobilePhone = {name: `Поле обов'язкове для заповнення.`};
    } else if (userForm?.callTypeReceive === 403) {
        const regex = /^380/;

        if (!regex.test(userForm.mobilePhone)) {
            errors.mobilePhone = {name: `Використовуйте формат номеру 380xxxxxxxxx`};
        } else if (regex.test(userForm.mobilePhone) && userForm.mobilePhone.length !== 12) {
            errors.mobilePhone = {name: `Використовуйте формат номеру 380xxxxxxxxx`};
        }
    }

    if (!userForm.roles || !userForm.roles?.length) {
        errors.roles = true;
    }

    if (!userForm.password) {
        errors.password = true;
    } else {
        const regex = /^(?=.*[a-zа-я])(?=.*[A-ZА-Я])(?=.*\d)[\d\S]{8,}$/;


        if (!regex.test(userForm.password)) {
            errors.password = {password: 'Пароль повинен містити мінімум одну велику літеру, одну маленьку літеру, одну цифру та містити не менше 8 символів.'};
        }
    }

    if (userForm?.userTrunks?.length) {
        const allUserTrunkPhoneIdNotNull = Array?.isArray(userForm.userTrunks) && userForm.userTrunks.every((item: {
            trunkId: any;
        }) => item.trunkId !== null);


        if (!allUserTrunkPhoneIdNotNull) {
            errors.userTrunks = {userTrunks: 'Виберіть номер лінії*'};
        }
    } else {
        errors.userTrunks = {userTrunks: 'Виберіть транк та номер лінії*'};
    }

    // if (!userForm.recruitedDate) {
    //     errors.recruitedDate = true;
    // }
    if (!userForm.branchId) {
        errors.branchId = true;
    }

    // if (!userForm.phoneNo) {
    //     errors.phoneNo = true;
    // }

    if (!userForm.languageId) {
        errors.languageId = true;
    }

    if (!userForm.email) {
        errors.email = true;
    } else {
        const emailPattern = /^[\w.%+-]+@[\w.-]+\.\w{2,}(?:\.\w{2,})?$/;
        if (emailPattern.test(userForm.email)) {} else {
            errors.email = {email: 'Не схоже на електронну адресу.\n' +
                    'Введіть, будь ласка, коректний Email- він необхідний для відновлення та зміни пароля користувача.'};
        }
    }

    if (!userForm.countryId) {
        errors.countryId = true;
    }

    if (!userForm.utc) {
        errors.utc = true;
    }

    if (!userForm.firstName) {
        errors.firstName = true;
    }

    if (!userForm.lastName) {
        errors.lastName = true;
    }

    return Object.keys(errors).length > 0 ? errors : null;
}

function validateCallCycle(callCycle: any): CallCycleErrors {
    const errors: CallCycleErrors = {};

    if (!callCycle.countCalls) {
        errors.countCalls = true;
    }

    if (callCycle.timeBetweenCalls === undefined || callCycle.timeBetweenCalls === null) {
        errors.timeBetweenCalls = true;
    } else if (callCycle.countCalls === 1 && callCycle.timeBetweenCalls !== 0) {
        errors.timeBetweenCalls = 'має бути 0*';
    } else if (callCycle.countCalls > 1 && callCycle.timeBetweenCalls <= 0) {
        errors.timeBetweenCalls = 'Вкажіть інтервал*';
    }

    return errors;
}

export function validateChangePassword(password: any): ChangePasswordErrors {
    const errors: ChangePasswordErrors = {};

    if (password.currentUser) {
        if (!password.oldPassword) {
            errors.oldPassword = true;
        }
    }

    if (!password.newPassword) {
        errors.newPassword = true;
    } else {
        const regex = /^(?=.*[a-zа-я])(?=.*[A-ZА-Я])(?=.*\d)[\d\S]{8,}$/;
        if (!regex.test(password.newPassword)) {
            errors.newPassword = {password: 'Пароль повинен містити мінімум одну велику літеру, одну маленьку літеру, одну цифру та містити не менше 8 символів.'};
        }
    }

    if (!password.confirmationPassword) {
        errors.confirmationPassword = 'Повторіть новий пароль*';
    } else if (password.newPassword && password.confirmationPassword !== password.newPassword) {
        errors.newPassword = true;
        errors.confirmationPassword = {password: 'Пароль  підтвердження не збігається з Новим паролем. Перевірте і повторіть спробу.'};
    }

    return errors;
}

export function validate(companiesData: any) {
    const errors: CampaignFormErrors = {};

    if (!companiesData.campaign.campaignName) {
        errors.name = {name: 'Назва кампанії не може бути порожньою'};
    }

    if (companiesData?.campaignUsers?.length) {
        const usersWithMissingLevel = companiesData?.campaignUsers?.filter((item: {
            userLevel: null | undefined;
        }) => item.userLevel === null || item.userLevel === undefined);

        if (usersWithMissingLevel.length > 0) {
            errors.campaignUsers = {campaignUsers: 'Рівень оператора не може бути порожнім*', campaignUsersWithoutLevel: usersWithMissingLevel};
        }
    }

    if (!companiesData.campaign.branchId) {
        errors.branchId = true;
    }

    // if (!companiesData?.campaign?.isGeneralTrunk) {
    //     if (!companiesData?.campaignUsers?.length && !companiesData?.skillGroups?.length) {
    //         errors.campaignUsers = {campaignUsers: 'Відсутні оператори або групи*'};
    //     }
    // }

    if (!companiesData.trunkId && companiesData.campaign.isGeneralTrunk) {
        errors.trankId = true;
    }

    const callCycleErrors = companiesData.callCycles.map((callCycle: any) => validateCallCycle(callCycle));

    if (callCycleErrors.some((callCycleError: {}) => Object.keys(callCycleError).length > 0)) {
        errors.callCycles = callCycleErrors;
    }

    if (companiesData?.scheduler) {
        if (new Date(companiesData.scheduler.startDate) > new Date(companiesData.scheduler.endDate)) {
            errors.startDate = true;
            errors.endDate = true;
        }
    }

    if (companiesData?.scheduler) {
        if (new Date(companiesData.scheduler.startTime) > new Date(companiesData.scheduler.endTime)) {
            errors.startTime = true;
            errors.endTime = true;
        }
    }

    return Object.keys(errors).length > 0 ? errors : null;
}

export function validateTrunkData(trunkData: any) {
    const errors: TrunkFormErrors = {};

    if (!trunkData.name) {
        errors.name = {name: 'Вкажіть назву транка *'};
    }

    if (!trunkData.phones.length) {
        errors.phones = {name: 'Відсутні телефони *'};
    }

    if (trunkData.listInboundPhones.length) {
        const hasEmptyStringsOrNull = trunkData.listInboundPhones.some((phone: string | null) =>
            phone === null || phone.trim() === ''
        );

        const hasInvalidLength = trunkData.listInboundPhones.some((phone: string | null) =>
            phone !== null && phone.trim() !== '' && phone.trim().length < 12
        );

        const hasInvalidPrefix = trunkData.listInboundPhones.some((phone: string | null) =>
            phone !== null && phone.trim() !== '' && !phone.trim().startsWith('380')
        );

        if (hasEmptyStringsOrNull) {
            errors.listInboundPhones = {listInboundPhones: 'Поле номера не може бути порожнім *'};
        } else if (hasInvalidLength){
            errors.listInboundPhones = { listInboundPhones: 'Номер не може бути менше 12 символів *' };
        } else if (hasInvalidPrefix) {
            errors.listInboundPhones = { listInboundPhones: 'Номери повинні починатися з 380 *' };
        }
    }

    if (trunkData.listOutboundPhones.length) {
        const hasEmptyStringsOrNull = trunkData.listOutboundPhones.some((phone: string | null) =>
            phone === null || phone.trim() === ''
        );

        const hasInvalidLength = trunkData.listOutboundPhones.some((phone: string | null) =>
            phone !== null && phone.trim() !== '' && phone.trim().length < 12
        );

        const hasInvalidPrefix = trunkData.listOutboundPhones.some((phone: string | null) =>
            phone !== null && phone.trim() !== '' && !phone.trim().startsWith('380')
        );

        if (hasEmptyStringsOrNull) {
            errors.listOutboundPhones = {listOutboundPhones: 'Поле номера не може бути порожнім *'};
        } else if (hasInvalidLength) {
            errors.listOutboundPhones = { listOutboundPhones: 'Номер не може бути менше 12 символів *' };
        } else if (hasInvalidPrefix) {
            errors.listOutboundPhones = { listOutboundPhones: 'Номери повинні починатися з 380 *' };
        }
    }

    return Object.keys(errors).length > 0 ? errors : null;
}

export function validateQueueData(queueData: any) {
    const errors: QueueFormErrors = {};

    if (!queueData.queue.name) {
        errors.name = {name: 'Вкажіть назву черги *'};
    }

    if (queueData.queueACD.schedulerAudioId && queueData.queueACD.isSchedulerAudio) {
        if (!queueData.queueACD.schedulerAudioTime) {
            errors.schedulerAudioTime = {schedulerAudioTime: 'Поле обов\'язкове для заповнення. Не може бути пустим. *'};
        }
        if (queueData.queueACD.schedulerAudioTime && queueData.queueACD.schedulerAudioTime < 10) {
            errors.schedulerAudioTime = {schedulerAudioTime: 'Значення не може бути меньше 10. *'};
        }
    }

    if (queueData.queueACD.mwtAudioId && queueData.queueACD.isMWTAudio) {
        if (!queueData.queueACD.mwtAudioTime) {
            errors.mwtAudioTime = {mwtAudioTime: 'Поле обов\'язкове для заповнення. Не може бути пустим. *'};
        }
        if (queueData.queueACD.mwtAudioTime && queueData.queueACD.mwtAudioTime < 10) {
            errors.mwtAudioTime = {mwtAudioTime: 'Значення не може бути меньше 10. *'};
        }
    }

    if (!queueData.queueBranches.length) {
        errors.branchId = true;
    }

    if (!queueData.queueACD.mwt && !PROD) {
        errors.mwt = {mwt: 'Поле обов\'язкове для заповнення. Не може бути пустим. *'};
    }
    if (queueData.queueACD.mwt && queueData.queueACD.mwt < 10 && !PROD) {
        errors.mwt = {mwt: 'Значення не може бути меньше 10. *'};
    }

    if (!queueData.queueACD.mwtNoAgent && !PROD) {
        errors.mwtNoAgent = {mwtNoAgent: 'Поле обов\'язкове для заповнення. Не може бути пустим. *'};
    }
    if (queueData.queueACD.mwtNoAgent && queueData.queueACD.mwtNoAgent < 1 && !PROD) {
        errors.mwtNoAgent = {mwtNoAgent: 'Значення не може бути меньше 1. *'};
    }

    if (queueData.queueACD.mwtNoAgentAudioId && queueData.queueACD.isMWTNoAgentAudio) {
        if (!queueData.queueACD.mwtNoAgentAudioTime) {
            errors.mwtNoAgentAudioTime = {mwtNoAgentAudioTime: 'Поле обов\'язкове для заповнення. Не може бути пустим. *'};
        }
        if (queueData.queueACD.mwtNoAgentAudioTime && queueData.queueACD.mwtNoAgentAudioTime < 10) {
            errors.mwtNoAgentAudioTime = {mwtNoAgentAudioTime: 'Значення не може бути меньше 10. *'};
        }
    }

    if (queueData.queueACD.schedulerStrategy === 202) {
        if (!queueData.queueACD.schedulerStrategyAction) {
            errors.schedulerStrategyAction = {schedulerStrategyAction: 'Поле обов\'язкове для заповнення. Не може бути пустим. *'};
        }
    }

    if (queueData.queueACD.mwtStrategy === 202 && !PROD) {
        if (!queueData.queueACD.mwtStrategyAction && !PROD) {
            errors.mwtStrategyAction = {mwtStrategyAction: 'Поле обов\'язкове для заповнення. Не може бути пустим. *'};
        }
    }

    if (queueData.queueACD.mwtNoAgentStrategy === 202 && !PROD) {
        if (!queueData.queueACD.mwtNoAgentStrategyAction && !PROD) {
            errors.mwtNoAgentStrategyAction = {mwtNoAgentStrategyAction: 'Поле обов\'язкове для заповнення. Не може бути пустим. *'};
        }
    }

    if (!queueData.queueACD.mohAudioId) {
        errors.mohAudioId = {mohAudioId: 'Поле обов\'язкове для заповнення. Не може бути пустим. *'};
    }

    if (!queueData.queueACD.schedulerStrategy) {
        errors.schedulerStrategy = {schedulerStrategy: 'Поле обов\'язкове для заповнення. Не може бути пустим. *'};
    }

    if (!queueData.queueACD.mwtStrategy && !PROD) {
        errors.mwtStrategy = {mwtStrategy: 'Поле обов\'язкове для заповнення. Не може бути пустим. *'};
    }

    if (!queueData.queueACD.mwtNoAgentStrategy && !PROD) {
        errors.mwtNoAgentStrategy = {mwtNoAgentStrategy: 'Поле обов\'язкове для заповнення. Не може бути пустим. *'};
    }

    if (!queueData.queueACD.mohAudioTime || queueData.queueACD.mohAudioTime < 10) {
        let errorMessage = 'Поле обов\'язкове для заповнення. Не може бути пустим. *';
        if (queueData.queueACD.mohAudioTime && queueData.queueACD.mohAudioTime < 10) {
            errorMessage = 'Значення не може бути меньше 10. *';
        }
        errors.mohAudioTime = {mohAudioTime: errorMessage};
    }

    if (queueData?.queue?.isGeneralTrunk) {
        const allTrankPhoneIdNotNull = Array.isArray(queueData.queueTrunks) && queueData.queueTrunks.every((item: {
            trunkPhoneId: any;
        }) => item.trunkPhoneId !== null);

        if (!allTrankPhoneIdNotNull) {
            errors.queueTranks = {queueTranks: 'Виберіть транк та номер лінії*'};
        }
    } else {
        // if (!queueData?.queueUsers?.length && !queueData?.skillGroups?.length) {
        //     errors.queueUsers = {queueUsers: 'Відсутні оператори або групи*'};
        // }
    }

    if (queueData?.queueUsers?.length) {
        const usersWithMissingLevel = queueData.queueUsers.filter((item: {
            userLevel: null | undefined;
        }) => item.userLevel === null || item.userLevel === undefined);

        if (usersWithMissingLevel.length > 0) {
            errors.queueUsers = {queueUsers: 'Рівень оператора не може бути порожнім*', queueUsersWithoutLevel: usersWithMissingLevel};
        }
    }

    if (!queueData?.scheduler?.days?.length) {
        errors.scheduler = {scheduler: 'Графік роботи черги не обрано! Розклад не може бути пустий!*'};
    } else {
        const allStartTimesBeforeEndTimes = queueData?.scheduler?.days?.every((day: {
            start: string | number | Date;
            end: string | number | Date;
        }) => {
            const startTime = new Date(day.start);
            const endTime = new Date(day.end);
            return startTime < endTime;
        });
        if (!allStartTimesBeforeEndTimes) {
            errors.scheduler = {scheduler: 'Відсутній час або час початку перевищує час завершення*'};
        }
    }

    return Object.keys(errors).length > 0 ? errors : null;
}

export function validateBranchForm(branchData: any) {
    const errors: BranchDataFormErrors = {};

    if (!branchData.name) {
        errors.name = {name: 'Ім\'я поточного бранча не може бути порожньою'};
    }

    // if (!branchData.parentId) {
    //     errors.parentId = {parentId: 'Ім\'я батьківського бранчу не може бути порожньою'};
    // }

    return Object.keys(errors).length > 0 ? errors : null;
}

export function validateRoleForm(rolesData: any) {
    const errors: RoleDataFormErrors = {};
    const hasPermissionIdNotNull = rolesData?.roleModules.find((module: {
        permissionId: null;
    }) => module.permissionId !== null);
    if (!rolesData.name) {
        errors.name = {name: 'Назва ролі не може бути порожньою'};
    }

    if (!hasPermissionIdNotNull) {
        errors.permissionId = {description: 'Додайте доступний розділ'};
    }

    if (!rolesData.roleBranches?.length) {
        errors.branchId = true;
    }

    return Object.keys(errors).length > 0 ? errors : null;
}

export function validateStatusForm(statusData: any) {
    const errors: StatusDataFormErrors = {};
    if (!statusData.statusName) {
        errors.name = {name: 'Назва статусу не може бути порожньою'};
    }
    if (!statusData.timeStatus) {
        errors.timeStatus = {timeStatus: 'Не може бути порожньою'};
    }

    if (!statusData.statusBranches?.length) {
        errors.branchId = true;
    }

    return Object.keys(errors).length > 0 ? errors : null;
}

export function validateAudioForm(audioData: any) {
    const errors: AudioDataFormErrors = {};
    if (!audioData.audioName) {
        errors.audioName = {name: 'Назва аудіо не може бути порожньою'};
    }
    // if (!audioData.description) {
    //     errors.description = {description: 'Не може бути порожньою'};
    // }

    if (!audioData.audioBranches.length) {
        errors.branchId = true;
    }

    if (!audioData.audioFileName) {
        errors.audioFileName = {audioFileName: 'Завантажте аудіо'};
    }

    return Object.keys(errors).length > 0 ? errors : null;
}

export function validateRecoveryEmail(data: any) {
    const errors: recoveryEmailErrors = {};
    if (!data) {
        errors.name = {name: 'Поле не може бути порожнім*'};
    }

    return Object.keys(errors).length > 0 ? errors : null;
}


