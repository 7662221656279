import {classNames} from "primereact/utils";
import {ProgressBar} from "primereact/progressbar";
import {Badge} from "primereact/badge";
import React, {useEffect, useState} from "react";
import {Button} from "primereact/button";
import {getTempUrl, uploadTempUrl} from "../../../../redux/api/apiMonitoringList";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {checkPermissions} from "../../../../redux/permissions/permissionsUtils";
import {changeRecallNumber, setRecallNumber} from "../../../../redux/actions/actionsWebRTC";
import {Divider} from "primereact/divider";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {SET_USER_STATUS_SUCCESS} from "../../../../redux/types/typesUsersList";
import AudioPlayer from "../../../Monitoring/AudioPlayer";

const StatisticsListDetails = (props: any) => {
    const dispatch = useAppDispatch();
    const statisticData = useAppSelector(state => state.StatisticsListReducer?.statisticData);
    const statusDictionary = useAppSelector(state => state.MonitoringListReducer.statusDictionary);
    const detailsLoading = useAppSelector(state => state.StatisticsListReducer.detailsLoading);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as any;
    const playerPermissions = checkPermissions(rolesVision, 'Statistic');
    const audioUrl = useAppSelector(state => state.MonitoringListReducer.audioUrl);
    const audioUrlLoading = useAppSelector(state => state.MonitoringListReducer.audioUrlLoading);
    const audioUrlUploadLoading = useAppSelector(state => state.MonitoringListReducer.audioUrlUploadLoading);
    const recallNumberChanged = useAppSelector(state => state.WebRTCReducer.recallNumberChanged);
    const [timeStamp, setTimeStamp] = useState<any>(null);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const [visible, setVisible] = useState(false);
    const callResetDictionary = useAppSelector(state => state.MonitoringListReducer.callResetDictionary);
    const forcedClosingDictionary = useAppSelector(state => state.MonitoringListReducer.forcedClosingDictionary);
    const ownerTypeDictionary = useAppSelector(state => state.MonitoringListReducer.ownerTypeDictionary);

    const {
        visible: hasPlayerVisiblePermission,
        update: hasPlayerUpdatePermission,
    } = playerPermissions;

    let foundStatus = statusDictionary?.filter((status: any) => status?.dictionaryId === statisticData?.status);

    const statusClass = classNames({
        'p-badge status-active': foundStatus?.[0]?.dictionaryId === 101,
        'p-badge status-success': foundStatus?.[0]?.dictionaryId === 103,
        'p-badge status-unsuccessful': foundStatus?.[0]?.dictionaryId === 104,
        'p-badge status-action': foundStatus?.[0]?.dictionaryId === 'ACTION',
        'p-badge status-processing': foundStatus?.[0]?.dictionaryId === 102
    });

    const handlePlay = () => {
        setTimeStamp(null);
        dispatch(getTempUrl(jwtToken?.jwtToken, statisticData?.sessionId, statisticData?.id));
    };

    const checkBanUser = () => {
        let data = {
            nameTo: statisticData?.ani,
            sessionId: statisticData?.groupSessionId
        }
        dispatch(setRecallNumber(data));
        dispatch(changeRecallNumber(!recallNumberChanged));
        if (windowWidth <= 1248) {
            props.setSelectedRowData(null);
            props.setSelectedRowExpansion(null);
        }
    }

    const audioHeaderBody = () => {
        return <div>Запис дзвінка</div>
    }

    const copyAudio = (audioUrl: any) => {
        const copyToClipboard = (text: string) => {
            const tempElement = document.createElement('textarea');
            tempElement.value = text;
            document.body.appendChild(tempElement);
            tempElement.select();
            document.execCommand('copy');
            document.body.removeChild(tempElement);
        }
        copyToClipboard(audioUrl);
        dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: 'Посилання аудіо скопійована в буфер обміну'}});
    }

    const audioBodyTemplate = () => {
        return hasPlayerVisiblePermission ? <>{(
            <div className="field col-12 md:col-12 flex align-items-center mb-0">
                <Button icon="pi pi-caret-right" disabled={statisticData?.status === 101 || statisticData?.status === 102} loading={audioUrlLoading} onClick={() => {
                    handlePlay();
                    setVisible(true);
                }} className="bg-primary" label="Прослухати"/>
            </div>)}
            {audioUrl && <Dialog header={audioHeaderBody} visible={visible} style={{ width: '25vw' }} className="pb-0 audio-dialog" onHide={() => setVisible(false)}><div
                className={`field col-12 md:col-12 flex flex-column align-items-center relative absolute outline-none border-none shadow-none border-circle save-mp3 text-lg mt-3 m-0 ${!audioUrl && 'opacity-40'}`}>
                <div className="w-full relative">
                    <AudioPlayer handleResetPlayer={props.handleResetPlayer} timeStamp={timeStamp}
                                 setTimeStamp={setTimeStamp}/>
                    {hasPlayerUpdatePermission && <Button
                        onClick={() => dispatch(uploadTempUrl(jwtToken?.jwtToken, statisticData?.sessionId, statisticData?.id))}
                        disabled={!audioUrl} icon="pi pi-download"
                        loading={audioUrlUploadLoading}
                        style={{marginLeft: '10px', top: '44px'}}
                        className={`absolute outline-none border-none shadow-none border-circle save-mp3 text-lg ${audioUrl ? 'opacity-100' : 'opacity-50'}`}
                    />}
                    {hasPlayerVisiblePermission && <Button
                        onClick={() => copyAudio(audioUrl)}
                        disabled={!audioUrl} icon="pi pi-copy"
                        style={{marginLeft: '10px', top: '44px'}}
                        className={`absolute outline-none border-none shadow-none border-circle save-mp3 text-lg ml-6 ${audioUrl ? 'opacity-100' : 'opacity-50'}`}
                    />}
                </div>
            </div> </Dialog>}
        </> : ''
    }

    const formatDateTime = (dateTimeStr: string): string => {
        const date = new Date(dateTimeStr);

        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();

        return `${hours}:${minutes}:${seconds} ${day}/${month}/${year}`;
    };

    // const convertMillisecondsToTime = (totalMilliseconds: number): string => {
    //     const hours = Math.floor(totalMilliseconds / (1000 * 60 * 60));
    //     const minutes = Math.floor((totalMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    //     const seconds = Math.floor((totalMilliseconds % (1000 * 60)) / 1000);
    //
    //     return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    // };

    const convertSecondsToTime = (totalSeconds: number): string => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = Math.floor(totalSeconds % 60);

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };
    const foundCallReset = callResetDictionary?.filter((item: any) => item?.dictionaryId === statisticData?.callReset);
    const foundAmdHangup = forcedClosingDictionary?.filter((item: any) => item?.dictionaryId === statisticData?.forcedClosing);
    let foundOwnerType = ownerTypeDictionary?.filter((status: any) => status?.dictionaryId === statisticData?.ownerType);

    const fields = [
        {label: "Оператор", value: statisticData.fullName || '- - -'},
        ...(statisticData?.campaignName || statisticData?.queueName ? [{label: statisticData?.campaignName ? 'Кампанія' : 'Черга', value: statisticData.campaignName || statisticData.queueName}] : []),
        ...(statisticData?.campaignId || statisticData?.queueId ? [{label: statisticData?.campaignId ? 'ID кампанії' : 'ID Черги', value: statisticData.campaignId || statisticData.queueId}] : []),
        {label: "Тривалість дзвінка", value: statisticData.fullCallDuration && statisticData?.fullCallDuration !== -1 ? convertSecondsToTime(statisticData.fullCallDuration) : statisticData.fullCallDuration === 0 ? '00:00:00' : '- - -'},
        {label: "Тривалість розмови", value: statisticData.duration && statisticData.duration !== -1 ? convertSecondsToTime(statisticData.duration) : statisticData.duration === 0 ? '00:00:00' : '- - -'},
        {label: "Реакція Клієнта", value: statisticData.reactionClient && statisticData.reactionClient !== -1 ? convertSecondsToTime(statisticData.reactionClient) : statisticData.reactionClient === 0 ? '00:00:00' : '- - -'},
        {label: "Реакція Оператора", value: statisticData.reactionOperator && statisticData.reactionOperator !== -1 ? convertSecondsToTime(statisticData.reactionOperator) : statisticData.reactionOperator === 0 ? '00:00:00' : '- - -'},
        {label: "Завершив", value: foundCallReset?.[0]?.name || '- - -'},
        {label: "Причина завершення", value: foundAmdHangup?.[0]?.name || '- - -'},
        {label: "Початок", value: statisticData.start ? formatDateTime(statisticData.start) : '- - -'},
        {label: "Завершення", value: statisticData.end ? formatDateTime(statisticData.end) : '- - -'}
    ];

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="relative">
            {detailsLoading && <ProgressBar mode="indeterminate" style={{height: '6px'}}></ProgressBar>}
            <div className={classNames('grid', {'opacity-50': detailsLoading})}
                 style={{paddingTop: "0.3rem", padding: '0.3rem 10px 5px'}}>
                {windowWidth > 1248 &&
                    <div className="grid col-12 md:col-12 mb-0 px-0 w-full justify-content-between">
                        <div className="absolute top-0 right-0">
                            <button className="close-button block cursor-pointer ml-auto"
                                    onClick={() => {
                                        props.setSelectedRowData(null);
                                        props.setSelectedRowExpansion(null);
                                    }
                                    }><span className="pi pi-times"></span>
                            </button>
                        </div>
                        <div className="field col-12 md:col-5 flex align-items-start m-0">
                            <div className="field col-12 md:col-5 flex m-0 py-0 flex-column">
                                {statisticData.pathSegment ?
                                    <a target="_blank" className="mb-2" href={`${statisticData.pathSegment}`}>{statisticData.contactName}</a> :
                                    <p className="m-0 mb-2">{statisticData.contactName}</p>}
                                <div className="flex align-items-center">
                                    <div className="mr-2">{statisticData?.ani}</div>
                                </div>
                            </div>
                            <div className="field col-12 md:col-7 flex-column flex m-0 py-0">
                                <Button icon="pi pi-phone" label="Подзвонити" className="status-success border-none max-w-9rem" onClick={() => {
                                    checkBanUser();
                                }}></Button>
                            </div>
                        </div>
                        <div className="field col-12 md:col-5 flex align-items-center justify-content-end m-0 pr-4">
                            <div className="field col-12 md:col-12 flex align-items-center m-0 p-0">
                                <div className="field col-6 md:col-9 flex align-items-center justify-content-end mb-0">
                                    {
                                        statisticData?.callType === 'transferInternal' && !statisticData.isDeclined ? (
                                            <div className="relative">
                                                <i className="pi pi-reply p-2 bg-green-500 border-circle text-white" style={{transform: 'rotate(90deg) scaleX(1) scaleY(-1)'}}></i>
                                            </div>
                                        ) : statisticData?.callType === 'transferInternal' && statisticData.isDeclined ? (
                                                <div className="relative">
                                                    <i className="pi pi-reply p-2 bg-green-500 border-circle text-white" style={{transform: 'rotate(90deg) scaleX(1) scaleY(-1)'}}></i>
                                                    <div className="absolute" style={{top: '-5px', left: '18px'}}>
                                                        <div className="border-circle relative"
                                                             style={{backgroundColor: 'red', width: '14px', height: '14px'}}>
                                                            <i className="pi pi-times text-white absolute left-50 top-50 kk"
                                                               style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                                                        </div>
                                                    </div>
                                                </div>) :

                                            statisticData?.callType === 'transferExternal' && !statisticData.isDeclined ? (
                                                <div className="relative">
                                                    <i className="pi pi-reply p-2 bg-primary border-circle text-white" style={{transform: 'rotate(90deg) scaleX(-1) scaleY(-1)'}}></i>
                                                </div>
                                            ) : statisticData?.callType === 'transferInternal' && statisticData.isDeclined ? (
                                                    <div className="relative">
                                                        <i className="pi pi-reply p-2 bg-primary border-circle text-white" style={{transform: 'rotate(90deg) scaleX(-1) scaleY(-1)'}}></i>
                                                        <div className="absolute" style={{top: '-5px', left: '18px'}}>
                                                            <div className="border-circle relative"
                                                                 style={{backgroundColor: 'red', width: '14px', height: '14px'}}>
                                                                <i className="pi pi-times text-white absolute left-50 top-50 kk"
                                                                   style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                                                            </div>
                                                        </div>
                                                    </div>) :

                                                statisticData.callType === 'callback' ? (
                                                    <div className="relative">
                                                        <i className="pi pi-undo p-2 border-circle text-white" style={{backgroundColor: "#fda610"}}></i>
                                                    </div>
                                                ) : statisticData.callType === 'internal' && !statisticData.isDeclined ? (
                                                    <div className="relative">
                                                        <i className="pi pi-arrow-down-left p-2 border-circle bg-green-500 text-white"></i>
                                                    </div>
                                                ) : statisticData.callType === 'internal' && statisticData.isDeclined ? (
                                                    <div className="relative">
                                                        <i className="pi pi-arrow-down-left p-2 border-circle bg-green-500 text-white"></i>
                                                        <div className="absolute" style={{top: '-5px', left: '18px'}}>
                                                            <div className="border-circle relative"
                                                                 style={{backgroundColor: 'red', width: '14px', height: '14px'}}>
                                                                <i className="pi pi-times text-white absolute left-50 top-50 kk"
                                                                   style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                                                            </div>
                                                        </div>
                                                    </div>) : statisticData.callType === 'external' && !statisticData.isDeclined ? (
                                                    <>
                                                        <i className="pi pi-arrow-up-right bg-primary p-2 border-circle text-white"></i>
                                                    </>
                                                ) : statisticData.callType === 'external' && statisticData.isDeclined ? (
                                                    <div className="relative">
                                                        <i className="pi pi-arrow-up-right bg-primary p-2 border-circle text-white"></i>
                                                        <div className="absolute" style={{top: '-5px', left: '18px'}}>
                                                            <div className="border-circle relative"
                                                                 style={{backgroundColor: 'red', width: '14px', height: '14px'}}>
                                                                <i className="pi pi-times text-white absolute left-50 top-50 kk"
                                                                   style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                                                            </div>
                                                        </div>
                                                    </div>) : <div className="relative">
                                                    <i className="pi pi-arrow-down-left p-2 border-circle bg-green-500 text-white"></i>
                                                </div>
                                    }
                                    <div className="ml-2 font-bold">
                                        {statisticData.direction}
                                    </div>
                                </div>
                                <div className="field col-6 md:col-3 flex align-items-center mb-0 py-0 pr-4">
                                    <Badge value={foundStatus?.[0]?.name}
                                           className={`${statusClass} text-white customer-badge text-xs ml-auto p-0`}
                                           style={{borderRadius: '6px', maxWidth: '95px', minWidth: '95px'}}/>
                                </div>
                            </div>

                        </div>
                        <Divider className="m-0 ml-2"/>
                        <div className="field col-12 md:col-9 flex align-items-center m-0 py-0 flex-wrap">
                            <div className="field col-12 md:col-2 flex align-items-center m-0">
                                <p>Власник дзвінка</p>
                            </div>
                            <div className="field col-12 md:col-4 flex align-items-center m-0" style={{borderRight: `1px solid rgba(255, 255, 255, 0.15)`}}>
                                <p>{statisticData.ownerUserName || '- - -'}</p>
                            </div>
                            <div className="field col-12 md:col-2 flex m-0">
                                <p>Тип власника</p>
                            </div>
                            <div className="field col-12 md:col-4 flex align-items-center m-0">
                                <p>{foundOwnerType?.[0]?.description || '- - -'}</p>
                            </div>
                            <div className="field col-12 md:col-2 flex m-0">
                                <p>Оператор</p>
                            </div>
                            <div className="field col-12 md:col-4 flex align-items-center m-0" style={{borderRight: `1px solid rgba(255, 255, 255, 0.15)`}}>
                                <p>{statisticData.fullName || '- - -'}</p>
                            </div>
                            <div className="field col-12 md:col-2 flex align-items-center m-0">
                                {statisticData?.campaignId ? <p>ID / Назва Кампанii</p> : statisticData?.queueId ? <p>ID / Назва Черги</p> : <></>}
                            </div>
                            <div className="field col-12 md:col-4 flex align-items-center m-0">
                                {statisticData?.campaignId ?
                                    <p>{statisticData?.campaignId} {statisticData?.campaignName}</p> :
                                    statisticData?.queueId ? <p>{statisticData?.queueId} {statisticData?.queueName}</p> :
                                        <></>
                                }
                            </div>                        </div>
                        <div className="field col-12 md:col-3 flex m-0 py-0" style={{borderLeft: `1px solid rgba(255, 255, 255, 0.15)`}}>
                            <div className="field col-12 md:col-4 flex m-0">
                                <p>Опис</p>
                            </div>
                            <div className="field col-4 md:col-8 flex m-0">
                                <p>{statisticData.description || '- - -'}</p>
                            </div>
                        </div>
                        <Divider className="m-0 ml-2"/>
                    </div>
                }
                {windowWidth <= 1248 && (statisticData.contactName || statisticData.pathSegment) &&
                    <div className="grid col-12 md:col-12 mb-0 px-0">
                        <div className="field col-12 md:col-12 flex align-items-center m-0">
                            {statisticData.pathSegment ?
                                <a target="_blank" className="mb-2" href={`${statisticData.pathSegment}`}>{statisticData.contactName}</a> :
                                <p className="m-0 mb-2">{statisticData.contactName}</p>}
                        </div>
                        {(statisticData.contactName || statisticData.pathSegment) && (
                            <div className="field col-12 md:col-12 p-0"
                                 style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>)}
                    </div>}
                {windowWidth > 1248 && <React.Fragment>
                    <DataTable className="col-12 md:col-12 pl-0 py-0" value={[statisticData]}
                    >
                        <Column
                            header={'Тривалість дзвінка'}
                            className="justify-content-start"
                            body={(data) => data.fullCallDuration && data?.fullCallDuration !== -1 ? convertSecondsToTime(data.fullCallDuration) : data.fullCallDuration === 0 ? '00:00:00' : '- - -'}
                            style={{cursor: 'pointer'}}
                        />
                        <Column
                            header={'Тривалість розмови'}
                            className="justify-content-start"
                            body={(data) => data.duration && data.duration !== -1 ? convertSecondsToTime(data.duration) : data.duration === 0 ? '00:00:00' : '- - -'}
                            style={{cursor: 'pointer'}}
                        />
                        <Column
                            header={'Реакція Клієнта'}
                            className="justify-content-start"
                            body={(data) => data.reactionClient && data.reactionClient !== -1 ? convertSecondsToTime(data.reactionClient) : data.reactionClient === 0 ? '00:00:00' : '- - -'}
                            style={{cursor: 'pointer'}}
                        />
                        <Column
                            header={'Реакція Оператора'}
                            className="justify-content-start"
                            body={(data) => data.reactionOperator && data.reactionOperator !== -1 ? convertSecondsToTime(data.reactionOperator) : data.reactionOperator === 0 ? '00:00:00' : '- - -'}
                            style={{cursor: 'pointer'}}
                        />
                        <Column
                            header={'Початок'}
                            className="justify-content-start"
                            body={(data) => data.start ? formatDateTime(data.start) : '- - -'}
                            style={{cursor: 'pointer'}}
                        />
                        <Column
                            header={'Завершення'}
                            className="justify-content-start"
                            body={(data) => data.end ? formatDateTime(data.end) : '- - -'}
                            style={{cursor: 'pointer'}}
                        />
                        <Column
                            header={'Завершив'}
                            className="justify-content-start"
                            body={() => foundCallReset?.[0]?.name || '- - -'}
                            style={{cursor: 'pointer'}}
                        />
                        <Column
                            header={'Причина завершення'}
                            className="justify-content-start"
                            body={() => foundAmdHangup?.[0]?.name || '- - -'}
                            style={{cursor: 'pointer'}}
                        />
                        <Column
                            header={'Запис дзвінка'}
                            className="justify-content-start"
                            body={audioBodyTemplate}
                            style={{cursor: 'pointer'}}
                        />
                    </DataTable>
                </React.Fragment>}
                {windowWidth <= 1248 && (
                    <React.Fragment>
                        <div className="field col-12 md:col-5 flex align-items-center m-0">
                            <p>Клiент</p>
                        </div>
                        <div className="field col-12 md:col-5 flex align-items-center m-0">
                            <p>{statisticData.ani}</p>
                        </div>
                        <div className="field col-12 md:col-2 flex align-items-center m-0">
                            <div className="pl-1" onClick={() => {
                                checkBanUser();
                            }}>
                                <i className="pi pi-phone status-success p-2 border-circle text-white mr-2 cursor-pointer"></i>
                            </div>
                        </div>
                        <div className="field col-12 md:col-5 flex align-items-center m-0">
                            <p>Власник дзвінка</p>
                        </div>
                        <div className="field col-12 md:col-5 flex align-items-center m-0">
                            <p>{statisticData.ownerUserName || '- - -'}</p>
                        </div>
                        <div className="field col-12 md:col-5 flex align-items-center m-0">
                            <p>Тип власника</p>
                        </div>
                        <div className="field col-12 md:col-5 flex align-items-center m-0">
                            <p>{foundOwnerType?.[0]?.description || '- - -'}</p>
                        </div>
                        {fields.map((field: any) => (
                            <React.Fragment key={field.label}>
                                <div className="field col-12 md:col-5 flex align-items-center m-0">
                                    <p>{field.label}</p>
                                </div>
                                <div className="field col-12 md:col-7 flex align-items-center m-0">
                                    <p>{field.value}</p>
                                </div>
                            </React.Fragment>
                        ))}
                        {hasPlayerVisiblePermission ? <>{!audioUrl && (
                            <div className="field col-12 md:col-12 flex align-items-center justify-content-center">
                                <Button disabled={statisticData?.status === 101 || statisticData?.status === 102} className="mt-3 flex align-items-center" loading={audioUrlLoading}
                                        onClick={() => handlePlay()}><p className="pl-1 m-0">Прослухати запис дзвінка</p>
                                </Button>
                            </div>)}
                            {audioUrl && (<div
                                className={`field col-12 md:col-12 flex flex-column align-items-center relative absolute outline-none border-none shadow-none border-circle save-mp3 text-lg mt-3 ${!audioUrl && 'opacity-40'}`}>
                                {hasPlayerUpdatePermission && <Button
                                    onClick={() => dispatch(uploadTempUrl(jwtToken?.jwtToken, statisticData?.sessionId, statisticData?.id))}
                                    disabled={!audioUrl} icon="pi pi-download"
                                    loading={audioUrlUploadLoading}
                                    className={`absolute outline-none border-none shadow-none border-circle save-mp3 text-lg ${audioUrl ? 'opacity-100' : 'opacity-50'}`}
                                />}
                                <div className="w-full">
                                    <AudioPlayer handleResetPlayer={props.handleResetPlayer} timeStamp={timeStamp}
                                                 setTimeStamp={setTimeStamp}/>
                                </div>
                            </div>)}
                        </> : ''}
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

export default StatisticsListDetails;
